import React from "react";
import { FormattedMessage, injectIntl, } from "gatsby-plugin-intl";

const Felszereltseg = ({ intl, project }) => {
  const info = `prop_icons_i_${project}`
  return (
    <div className="felszereltseg">
      <h3 className="felszerel_title">
        <FormattedMessage id="felszereltseg" />
      </h3>
      <div className="icons row">
        <div className="col-6">
          <div className="tulajdonsag">
            <img src="/icon-defib.png" alt="defibrillator" />
            <span>
              {intl.formatMessage({ id: "prop_icons.defibrillator" })}
            </span>
            <br />
            <span>{intl.formatMessage({ id: `${info}.defibrillator` })}</span>
          </div>
          <div className="tulajdonsag">
            <img src="/icon-eauto.png" alt="e auto" />
            <span>
              {intl.formatMessage({ id: "prop_icons.e_car_charging" })}
            </span>

            <br />
            <span>{intl.formatMessage({ id: `${info}.e_car_charging` })}</span>
          </div>
          <div className="tulajdonsag">
            <img src="/icon-okoshaz.png" alt="intelligent systems" />
            <span>
              {intl.formatMessage({ id: "prop_icons.intelligent_systems" })}
            </span>
            <br />
            <span>
              {intl.formatMessage({ id: `${info}.intelligent_systems` })}
            </span>
          </div>
        </div>
        <div className="col-6">
          <div className="tulajdonsag">
            <img src="/icon-shield.png" alt="safety" />
            <span>{intl.formatMessage({ id: "prop_icons.safety" })}</span>

            <br />
            <span>{intl.formatMessage({ id: `${info}.safety` })}</span>
          </div>
          <div className="tulajdonsag">
            <img src="/icon-zoldteto.png" alt="zoldteto" />
            <span>{intl.formatMessage({ id: "prop_icons.green_roof" })}</span>
            <br />
            <span>{intl.formatMessage({ id: `${info}.green_roof` })}</span>
          </div>
          <div className="tulajdonsag">
            <img src="/icon-elevator.png" alt="elevator" />
            <span>{intl.formatMessage({ id: "prop_icons.elevator" })}</span>
            <br />
            <span>{intl.formatMessage({ id: `${info}.elevator` })}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Felszereltseg)
