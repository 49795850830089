import React, { Component } from "react";
import Img from "gatsby-image";

import LakasData from "../../content/lakasok.yml";
import { gsap } from "gsap";
import Slider from "./Slider";

import {
  useIntl,
  Link,
  FormattedMessage,
  injectIntl,
  IntlProvider,
} from "gatsby-plugin-intl";
import ContactForm from "./contactform";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import ImageInfos from "../../content/images.json";

class Lakasok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lakas: null,
      show: false,
      contact: false,
    };
    this.renderIcons = this.renderIcons.bind(this);
    this.showLakas = this.showLakas.bind(this);
  }

  renderIcons() {
    return <img src='/icon-defib.png' alt='defibrilator' />;
  }

  setContact(e) {
    e.preventDefault();
    this.setState({
      contact: true,
    });
  }

  showLakas(e, lakas) {
    let show = lakas ? true : false;
    let contact = lakas ? this.state.contact : false;

    this.setState(
      {
        lakas: lakas,
        show: show,
        contact: contact,
      },
      () => {
        if (lakas) {
        } else {
          this.lakasAnimation = gsap.timeline();
          this.lakasAnimation.from(this.selectedLakas, {
            delay: 0,
            opacity: 0,
            duration: 1.5,
            x: 50,
          });
        }
      }
    );
  }

  render() {
    const ident = this.props.ident;
    const lang = this.props.lang;
    let intl = this.props.intl;

    let projekt = {};
    LakasData.projektek.map((data, index) => {
      if (data.ident == ident) {
        projekt = data;
      }
    });

    let lakaskepek = [];
    if (this.props.lakaskepek) {
      this.props.lakaskepek.nodes.map((lakaskep) => {
        if (
          this.state.lakas &&
          /[^/]*$/.exec(lakaskep.relativeDirectory)[0] == this.state.lakas.kepek
        ) {
          ImageInfos[lang].forEach((info) => {
            if (info.images.length) {
              info.images.forEach((inf) => {
                if (inf == lakaskep.base) {
                  lakaskep.info = info;
                }
              });
            }
          });
          lakaskepek.push(lakaskep);
        }
      });
    }

    return (
      <div className='lakasok'>
        <h3>{intl.formatMessage({ id: "lakasok" })}</h3>
        <div className='lakasok_content'>
          <div
            ref={(div) => (this.lakasTabla = div)}
            className={"tabla " + (this.state.show ? "hidden-d" : "")}
          >
            <div>
              {projekt
                ? projekt.lakasok.map((lakas, index) => {
                  return (
                    <span
                      onClick={(e) => this.showLakas(e, lakas)}
                      className={
                        "lakas " +
                        (this.state.lakas &&
                          this.state.lakas.lakas_id == lakas.lakas_id
                          ? "active"
                          : "")
                      }
                      key={`lakas_item_${index}`}
                    >
                      {lang == "en" ? lakas.csoport_en : lakas.csoport}{" "}
                      {lakas.lakas} - {lakas.osszterulet}
                      <div className='to-right'>
                        <span>
                          {intl.formatMessage({
                            id: `lakas_allapot.${lakas.status}`,
                          })}
                        </span>{" "}
                        <FaArrowRight />
                      </div>
                    </span>
                  );
                })
                : null}
            </div>
          </div>
          <div
            ref={(div) => (this.selectedLakas = div)}
            className={"lakas-selected " + (this.state.show ? "" : "hidden")}
          >
            {this.state.show ? (
              <>
                <div className='selected-content'>
                  <span
                    className='back-to-lakasok'
                    onClick={(e) => this.showLakas(e, null)}
                  >
                    <FaArrowLeft />{" "}
                    {intl.formatMessage({ id: "back-to-lakasok" })}
                  </span>
                  <div className='selected-main'>
                    {lang == "en"
                      ? this.state.lakas.csoport_en
                      : this.state.lakas.csoport}{" "}
                    {this.state.lakas.lakas}
                    <br />
                    {intl.formatMessage({ id: "osszterulet" })}:{" "}
                    {this.state.lakas.osszterulet}
                    {this.state.lakas.status !== "megnezem" ? (
                      <>
                        <br />
                        <i>
                          {intl.formatMessage({
                            id: `lakas_allapot.${this.state.lakas.status}`,
                          })}
                        </i>
                      </>
                    ) : null}
                  </div>
                  {this.state.contact ? (
                    <>
                      <ContactForm
                        interest={`lakás: ${this.state.lakas.csoport} ${this.state.lakas.lakas}, projekt: ${projekt.title}`}
                      />
                    </>
                  ) : (this.state.lakas?.helyseg?.length) ? (
                    <div className='helysegek'>
                      <span className='helysegek-title'>
                        {intl.formatMessage({ id: "helysegek" })}:
                      </span>
                      <table>
                        <tbody>
                          {this.state.lakas.helyseg.map((szoba, i) => (
                            <tr key={i}>
                              <td>
                                {lang == "en" ? szoba.name_en : szoba.name}
                              </td>
                              <td>{szoba.terulet}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  <div className='buttons'>
                    {this.state.lakas.file ? (
                      <a
                        className='button'
                        target='_blank'
                        href={`https://iproperty.hu/lakasok/${projekt.ident}/${this.state.lakas.file}`}
                        role='button'
                      >
                        {intl.formatMessage({ id: "file" })}
                      </a>
                    ) : null}
                    <a
                      className={
                        "button " +
                        (this.state.contact ||
                          this.state.lakas.status == "eladva"
                          ? "hidden"
                          : "")
                      }
                      onClick={(e) => this.setContact(e)}
                      role='button'
                    >
                      {intl.formatMessage({ id: "contact_title" })}
                    </a>
                  </div>
                </div>
                <div className='selected-slider'>
                  {lakaskepek.length ? (
                    <Slider kepek={lakaskepek} video={this.state.lakas.video} />
                  ) : (
                    <div className='placeholder'>
                      <Img fluid={this.props.placeholder} />
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Lakasok);
