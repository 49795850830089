import React from "react";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const Brossura = ({ brossura_image, brossura, siteUrl, brossura_kieg }) => {
  let intl = useIntl()

  return (
    <div className="brossura row">
      <div className="col-6">
        <Img fluid={brossura_image} />
      </div>
      <div className="col-6 brossure_content">
        <h3>{intl.formatMessage({ id: "brossura_title" })}</h3>

        <p>{intl.formatMessage({ id: "brossura_i" })}</p>

        <a target="_blank" href={`${siteUrl}/brossura/${brossura}`} className="button outline d-block">
          {intl.formatMessage({ id: "download" })}
        </a>

        {brossura_kieg ? (
          <>
            <p style={{ marginTop: "1.4em", marginBottom: "0", fontWeight: "600" }}>{intl.formatMessage({ id: "brossura_kieg_i" })}</p>
            {brossura_kieg.map((brossura, index) => (
              <a style={{ display: "block", marginTop: ".6em" }} target="_blank" href={`${siteUrl}/brossura/${brossura}`} key={index}>
                <span style={{ textDecoration: "underline" }}>{brossura}</span>
              </a>
            ))}
          </>
        ) : null}

      </div>
    </div>
  )
};

export default Brossura;
