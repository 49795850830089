import React, { useState } from "react";
import { injectIntl, useIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

function SliderFull({ images }) {
  const [index, setIndex] = useState(0);
  const length = images.length - 1;
  console.log(images);
  const handlePrevious = () => {
    if (index === 0) {
      setIndex(length);
    } else {
      setIndex(index - 1);
    }
  };

  const handleNext = () => {
    if (index === length) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const node = images[index];

  return (
    <div className='slider-full'>
      <div className='colors'>
        <div className='color' style={{ backgroundColor: node.colors.muted }}>
          &#160;
        </div>
        <div className='color' style={{ backgroundColor: node.colors.vibrant }}>
          &#160;
        </div>
        <div
          className='color'
          style={{ backgroundColor: node.colors.lightMuted }}
        >
          &#160;
        </div>
      </div>

      <div className='wrapper'>
        <Img fluid={node.childImageSharp.fluid} key={node.id} alt={node.id} />
      </div>

      <div className='buttons'>
        <button onClick={() => handlePrevious()}>
          <FaArrowLeft />
        </button>
        <button onClick={() => handleNext()}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
}

export default injectIntl(SliderFull);
