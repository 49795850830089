import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const WhyBuda = (props) => {
  const imageOne = props.data.imageOne.childImageSharp.fluid;
  const imageTwo = props.data.imageTwo.childImageSharp.fluid;
  const imageThree = props.data.imageThree.childImageSharp.fluid;
  let intl = useIntl();

  return (
    <div className="why-buda">
      <h3>{intl.formatMessage({ id: "why_buda_title" })}</h3>
      <p>{intl.formatMessage({ id: "why_buda" })}</p>

      <div className="images">
        <div className="box">
          <Img fluid={imageOne} />
        </div>
        <div className="box">
          <Img fluid={imageTwo} />
        </div>
        <div className="box">
          <Img fluid={imageThree} />
        </div>
      </div>
    </div>
  )
}

const budaQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "Belbuda.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 820) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "autopalya.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 820) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageThree: file(relativePath: { eq: "sport.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 820) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const WhyBudaContainer = (props) => {
  const data = useStaticQuery(budaQuery);
  return <WhyBuda {...props} data={data} />;
};

export default WhyBudaContainer;