import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import BackgroundImage from "gatsby-background-image";

import { Link, FormattedMessage } from "gatsby-plugin-intl";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout";
import Contact from "../components/contact";
import Brossura from "../components/Brossura";
import WhyBuda from "../components/WhyBuda";
import Felszereltseg from "../components/felszereltseg";
import Lakasok from "../components/lakasok";
import FullKepek from "../components/FullKepek";
import DoubleImage from "../components/doubleImage";
import DoubleEq from "../components/DoubleEq";
import Opinion from "../components/Opinion";
import SEO from "../components/Seo";
import FullPageImage from "../components/FullPageImage";

import Modal from "react-modal";

import { FaArrowLeft } from "react-icons/fa";

import { AiOutlineClose, AiFillPlayCircle } from "react-icons/ai";

class ProjectTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.handleClickModal = this.handleClickModal.bind(this);
  }

  handleClickModal(to) {
    this.setState({
      modal: to,
    });
  }

  render() {
    const projects = this.props.data.allMdx.edges;
    const intl = this.props.intl;
    const lang = this.props.pageContext.language;

    const { lakaskepek, fullkepek } = this.props.data;

    const { siteUrl, siteTitle } = this.props.data.site.siteMetadata;

    let project = {};

    projects.map(({ node }) => {
      if (node.frontmatter.lang == this.props.pageContext.language) {
        project = node;
      }
    });

    const shortcodes = {
      FullPageImage,
      Contact,
      WhyBuda,
      Lakasok,
      DoubleImage,
      Opinion,
      DoubleEq,
      FullKepek,
    };

    return (
      <Layout location={this.props.location} title={siteTitle}>
        {project.frontmatter ? (
          <>
            <SEO
              title={project.frontmatter.title}
              description={project.frontmatter.description || project.excerpt}
              article={true}
            />
            <article id='single-project' className={`transition-fade`}>
              <BackgroundImage
                fluid={project.frontmatter.thumbnailone.childImageSharp.fluid}
                className='project-header'
              >
                <div className='project-header-back'>
                  <Link to='/projects'>
                    <span>
                      <FaArrowLeft />
                      <FormattedMessage id='back_to_projects' />
                    </span>
                  </Link>
                </div>

                <div className='project-header-info' >
                  <div>
                    <span className='project-header-title'>
                      {project.frontmatter.title}
                    </span>
                    <span className='project-header-location'>
                      {project.frontmatter.location}
                    </span>
                  </div>
                  {project.frontmatter.logo &&
                    <div style={{ margin: "0 0 0 1em" }} className="project-logo">
                      <img style={{ maxHeight: "5em" }} src={project.frontmatter.logo.childImageSharp.fluid.src} alt={project.frontmatter.title} />
                    </div>
                  }
                </div>
              </BackgroundImage>

              <div className='project-content'>
                {project.frontmatter.end && (
                  <p className='project-content-excerpt-small'>
                    {project.frontmatter.end}
                  </p>
                )}
                {project.frontmatter.description && (
                  <p className='project-content-excerpt'>
                    {project.frontmatter.description}
                  </p>
                )}

                {project.frontmatter.ident == "goldmark" ? (
                  <>
                    <div
                      className='project-content-image'
                      onClick={() => this.handleClickModal(true)}
                    >
                      <Img
                        className='kg-image'
                        fluid={
                          project.frontmatter.thumbnailone.childImageSharp.fluid
                        }
                        alt={project.frontmatter.title}
                      />

                      <div className='play-btn-holder'>
                        <span className='video-start-btn'>
                          <AiFillPlayCircle size='6em' />
                        </span>
                      </div>
                    </div>

                    <Modal
                      isOpen={this.state.modal}
                      onRequestClose={() => this.handleClickModal(false)}
                      className='videoModal'
                      contentLabel='Video Modal'
                    >
                      <video className='lakasvideo' controls>
                        <source
                          src={`https://iproperty.hu/videos/goldmark.mp4`}
                          type='video/mp4'
                        />
                      </video>
                      <span
                        className='close-icon'
                        onClick={() => this.handleClickModal(false)}
                      >
                        <AiOutlineClose size='3em' color='#fff' />
                      </span>
                    </Modal>
                  </>
                ) : (
                  project.frontmatter.thumbnailone && (
                    <div className='project-content-image'>
                      <Img
                        className='kg-image'
                        fluid={
                          project.frontmatter.thumbnailone.childImageSharp.fluid
                        }
                        alt={project.frontmatter.title}
                      />
                    </div>
                  )
                )}

                {project.frontmatter.epitesz && (
                  <Opinion id={project.frontmatter.epitesz} lang={lang} />
                )}

                {project.frontmatter.lakasok ? (
                  <Lakasok
                    lang={lang}
                    ident={project.frontmatter.ident}
                    lakaskepek={lakaskepek}
                    placeholder={
                      project.frontmatter.thumbnailone.childImageSharp.fluid
                    }
                  />
                ) : null}

                <MDXRenderer
                  {...this.props}
                  components={shortcodes}
                  className='project-content-body'
                >
                  {project.body}
                </MDXRenderer>

                {project.frontmatter.felszereltseg ? (
                  <Felszereltseg project={project.frontmatter.ident} />
                ) : null}

                {project.frontmatter.brossura ? (
                  <Brossura
                    brossura={project.frontmatter.brossura}
                    brossura_kieg={project.frontmatter.brossura_kieg}
                    brossura_image={
                      project.frontmatter.brossura_image.childImageSharp.fluid
                    }
                    siteUrl={siteUrl}
                  />
                ) : null}

                {project.frontmatter.galeria ? (
                  <FullKepek lang={lang} kepek={fullkepek} />
                ) : null}

                {project.frontmatter.buda ? <WhyBuda /> : null}
              </div>
            </article>
          </>
        ) : (
          <>
            <SEO title='404: Not Found' />
            <h1>Not Found</h1>
            <FormattedMessage id='missing_content' />
          </>
        )}
      </Layout>
    );
  }
}

export default ProjectTemplate;

export const pageQuery = graphql`
  query BlogprojectBySlug(
    $slug: String!
    $lakaskepek: String!
    $fullkepek: String!
  ) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          body
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            main_title
            main_desc
            location
            brossura
            brossura_kieg
            brossura_image {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            ident
            lakasok
            galeria
            felszereltseg
            main_prior
            buda
            epitesz
            end
            lang
            thumbnailone {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    lakaskepek: allFile(
      filter: { absolutePath: { regex: $lakaskepek } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        base
        relativeDirectory
        colors {
          ...GatsbyImageColors
        }
        childImageSharp {
          fluid(quality: 100, maxWidth: 1360) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    fullkepek: allFile(
      filter: { absolutePath: { regex: $fullkepek } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        base
        relativeDirectory
        colors {
          ...GatsbyImageColors
        }
        childImageSharp {
          fluid(quality: 100, maxWidth: 1360) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
