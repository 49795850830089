import React, { useState } from "react";
import { injectIntl, useIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import Goldm_1 from "../../content/assets/videos/goldm_1.mp4";
import Goldm_3_1 from "../../content/assets/videos/goldm_3_1.mp4";
import Goldm_3_2 from "../../content/assets/videos/goldm_3_2.mp4";
import Goldm_3_p from "../../content/assets/videos/goldm_3_p.mp4";

const videos = {
  goldm_1: Goldm_1,
  goldm_3_1: Goldm_3_1,
  goldm_3_2: Goldm_3_2,
  goldm_3_p: Goldm_3_p,
};

function Slider({ kepek, video }) {
  const [index, setIndex] = useState(0);
  const [videoActive, setVideo] = useState(false);
  const length = kepek.length - 1;

  const handlePrevious = () => {
    if (index === length && videoActive) {
      setIndex(length);
      setVideo(false);
    }
    if (index === 0) {
      if (video) {
        if (videoActive) {
          setIndex(length);
          setVideo(false);
        } else {
          setVideo(true);
        }
      } else {
        setIndex(length);
      }
    } else {
      setIndex(index - 1);
      setVideo(false);
    }
  };

  const handleNext = () => {
    if (index === length) {
      if (video) {
        if (videoActive) {
          setIndex(0);
          setVideo(false);
        } else {
          setVideo(true);
        }
      } else {
        setIndex(0);
      }
    } else {
      setIndex(index + 1);
      setVideo(false);
    }
  };

  const node = kepek[index];
  const smaller = index === length ? kepek[0] : kepek[index + 1];

  return (
    <div className='slider'>
      {videoActive ? null : (
        <div className='colors'>
          <div className='color' style={{ backgroundColor: node.colors.muted }}>
            &#160;
          </div>
          <div
            className='color'
            style={{ backgroundColor: node.colors.vibrant }}
          >
            &#160;
          </div>
          <div
            className='color'
            style={{ backgroundColor: node.colors.lightMuted }}
          >
            &#160;
          </div>
        </div>
      )}

      <div className='wrapper'>
        {videoActive ? (
          <>
            <video className='lakasvideo' controls>
              <source src={videos[video]} type='video/mp4' />
            </video>
          </>
        ) : (
          <>
            <Img
              fluid={node.childImageSharp.fluid}
              key={node.id}
              alt={node.id}
            />

            {video && index === length ? null : (
              <div className='smaller'>
                <Img
                  objectFit='contain'
                  fluid={smaller.childImageSharp.fluid}
                  key={node.id}
                  alt={node.id}
                />
              </div>
            )}

            {node.info ? (
              <div className='img-info'>
                <span>{node.info.content}</span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className='buttons'>
        <button onClick={() => handlePrevious()}>
          <FaArrowLeft />
        </button>
        <button onClick={() => handleNext()}>
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
}

export default injectIntl(Slider);
