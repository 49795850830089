import React from "react";
import SliderFull from "./SliderFull";
import { injectIntl } from "gatsby-plugin-intl";

const FullKepek = ({ kepek }) => {
  let images = [];
  if (kepek) {
    images = kepek.nodes.map((projektkep) => {
      projektkep.info = null;
      return projektkep;
    });
  }

  return (
    <div className='fullGaleria'>
      {images.length ? <SliderFull images={images} /> : null}
    </div>
  );
}

export default injectIntl(FullKepek);
